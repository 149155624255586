
$(document).ready(function() {

  // Responsive Menu Toggle and Drop Down
  $('body').addClass('js');
  var $menu = $('#menu'),
      $menulink = $('.menu-link'),
      $menuTrigger = $('.has-subnav > a');

  $menulink.click(function(e) {
    e.preventDefault();
    $menulink.toggleClass('active');
    $menu.toggleClass('active');
  });

  $menuTrigger.click(function(e) {
    e.preventDefault();
    var $this = $(this);
    $this.toggleClass('active').next('ul').toggleClass('active');
  });
  
  // Project Listing Slider
  var owlProjectListing = $('.project-list-slider');

  owlProjectListing.owlCarousel({
      loop:false,
      margin:5,
      dots: false,
      nav:false,
      stagePadding: 40,
      responsive:{
          0:{
              items:1
          },
          480:{
              items:2
          },
          768:{
              items:3
          },
          1100:{
            items:4
          },
          1300:{
            items:5
          }
      }
  });

  $('.mtaPrev').on('click', function (e) {
      e.preventDefault();
      owlProjectListing.trigger('prev.owl');
  });
  $('.mtaNext').on('click', function (e) {
      e.preventDefault();
      owlProjectListing.trigger('next.owl');
  });

});


